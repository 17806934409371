export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    UserRoles = 3,
    Audit = 4,
    AccessRequests = 5,
    Projects = 6,
    Departments = 7,
    Timesheets = 8,
    SetExecutiveDirector = 9,
    CanViewSubordinatesTimesheets = 10,
    PayPeriods = 11,
    CalendarEventTypes = 12,
    Holidays = 13,
    CalendarEvents = 14,
    CanAssignUsersToCalendarEvents = 15,
    CanViewSubordinatesCalendars = 16,
    Vehicles = 17,
    HumanResourcePolicies = 18,
    UserHumanResourcePolicies = 19,
    RecurringWorkSchedules = 20,
    CanViewAllVehicles = 21,
    CanMarkVehiclesOutForMaintenance = 22,
    Reports = 23,
    CanAssignDepartments = 24,
    CanAssignShiftTimes = 25,
    CanAssignProjects = 26,
    CanAssignHolidayPayEligible = 27,
    MaintenanceScheduler = 28,
    CanViewAllTimesheets = 29,
    CanViewAllCalendars = 30,
    TrainingResources = 31,
    CanSubmitTimeOffRequestsAutoApprove = 32,
    CanAssignAccessAllReportData = 33,
    CanManageOtherUsersCalendarEntries = 34,
    TimeOffBalances = 35,
    CanViewAllTimeOffBalances = 36,
}
