import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const dupReq = req.clone({
      headers: req.headers.set('Timezone', Intl.DateTimeFormat().resolvedOptions().timeZone),
    });
    return next.handle(dupReq);
  }
}
