import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { sortByProperty } from '@mt-ng2/common-functions';

import { DaysOfTheWeek } from '@model/enums/days-of-the-week.enum';
import { ICountry } from '@model/interfaces/country';
import { IState } from '@model/interfaces/state';
import { IHoursAndMinutes } from '@model/interfaces/custom/hours-and-minutes';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private _states: IState[];
    private _countries: ICountry[];

    constructor(private http: HttpClient) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    const indexOfUS = answer.findIndex((country) => country.CountryCode === 'US');
                    answer.splice(0, 0, answer.splice(indexOfUS, 1)[0]);
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    getFormattedTime(totalMinutes: number): string {
        let hours = 0;
        let minutes = 0;
        if (totalMinutes) {
            hours = Math.floor(totalMinutes / 60);
            minutes = totalMinutes % 60;
        }

        return `${hours} hrs ${String(minutes)} mins`;
    }

    deviceType(): string {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 'tablet';
        } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return 'mobile';
        }
        return 'desktop';
    }

    sameDay(firstDate: Date, secondDate: Date): boolean {
        return firstDate.getFullYear() === secondDate.getFullYear() &&
            firstDate.getMonth() === secondDate.getMonth() &&
            firstDate.getDate() === secondDate.getDate();
    }

    compareTimeOnly(firstTime: Date, secondTime: Date): number {
        if (firstTime.getHours() < secondTime.getHours()) {
            return -1;
        }
        if (firstTime.getHours() > secondTime.getHours()) {
            return 1;
        }
        if (firstTime.getMinutes() < secondTime.getMinutes()) {
            return -1;
        }
        if (firstTime.getMinutes() > secondTime.getMinutes()) {
            return 1;
        }
        if (firstTime.getSeconds() < secondTime.getSeconds()) {
            return -1;
        }
        if (firstTime.getSeconds() > secondTime.getSeconds()) {
            return 1;
        }
        return 0;

    }

    addDays(date: Date, days: number): Date {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    getDaysOfTheWeek(): string[] {
        const daysOfTheWeek: string[] = [];
        for (const n in DaysOfTheWeek) {
            if (typeof DaysOfTheWeek[n] === 'number') {
                daysOfTheWeek.push(n);
            }
        }
        return daysOfTheWeek;
    }

    getWholeDaysDifference(earlierDate: Date, laterDate: Date): number {
        return Math.floor(this.getDaysDifference(earlierDate, laterDate));
    }

    getDaysDifference(earlierDate: Date, laterDate: Date): number {
        const seconds = (new Date(laterDate).getTime() - new Date(earlierDate).getTime() ) / 1000;
        return (seconds / 3600) / 24;
    }

    doDurationsOverlap(d1Start: Date | string, d1End: Date | string, d2Start: Date | string, d2End: Date | string): boolean {
        d1Start = new Date(d1Start);
        d1End = new Date(d1End);
        d2Start = new Date(d2Start);
        d2End = new Date(d2End);

        if (d1End < d2Start || d2End < d1Start) {
            return false;
        }
        return true;
    }

    // can convert 2023-02-08 15:00:00.0000000 -08:00
    convertUTCDateToLocalDate(date: Date): Date {
        const newDate = new Date(date.getTime());

        const offset = date.getTimezoneOffset() / 60;
        const offsetMin = date.getTimezoneOffset() % 60;

        const hours = date.getHours();
        const minutes = date.getMinutes();

        newDate.setHours(hours - offset, minutes - offsetMin);

        return newDate;
    }

    getUniqueValues<T>(array: T[]): T[] {
        return array.reduce((acc: T[], cur: T) => {
            if (!acc.includes(cur)) {
                acc.push(cur);
            }
            return acc;
        }, []);
    }

    addTimeToDate(date: Date, time: string): Date {
        const hour = +time.split(':')[0];
        const min = +time.split(':')[1];
        const result = new Date(date);
        result.setHours(hour, min, 0, 0);
        return result;
    }

    getHoursAndMinutesFromString(timeString: string): IHoursAndMinutes {
        const pieces = timeString.split(':');
        return {
            Hours: +pieces[0],
            Minutes: +pieces[1]
        } as IHoursAndMinutes;
    }

}
