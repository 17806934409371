// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    apiVersion: '1',
    appName: 'Vineland Housing Authority',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 1,
    baseApiUrl: '',
    docPath: 'docs/',
    googleApiKey: '', // Empty this string to disable google login
    imgPath: 'docs/images/',
    logger: false,
    msAuthConfig: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: '11b10b1b-2711-41d0-a872-db2e3c17ef2b',
        redirectUri: 'https://vhaportal.staging.milesapp.com/',
        authFlow: 'redirect' as const
    },
    production: false,
    siteName: 'vhaportal',
    submitHelpUrl: 'http://submit-help.testing.milesapp.com',
};
